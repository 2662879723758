.write {
    padding-top: 20px;
    background-color: rgb(215, 250, 220);
}

.writeImage{
    /* margin-left: 150px; */
    width:100%;
    height: 350px;
    border-radius: 10px;
    object-fit:contain;
}

.writeForm {
    position: relative;

}

.writeFormGroup {
    margin-left: 150px;
    display: flex;
    align-items: center;
}

.writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: gray;
    cursor: pointer;
    background-color: aqua;
}


.writeInput {
    font-size: 30px;
    border: none;
    padding: 20px;
    width: 70vw;
    margin-left:10px;
    margin-bottom:  10px;
}

.writeTextarea{
    margin-left:35px;
    border: 1px solid black;
    width: 70vw;


}
.writeInput:focus {
    outline:2px solid rgb(235, 26, 113);
}
/*
.writeText {
    font-size: 40px;
} */

.writeSubmit {
    position: absolute;
    top: 20px;
    right:170px;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    background-color: teal;
}