.single {
    /* width: 100%;
    text-align: center;
    padding: 5px 0;
    display: flex;
    font-family: Josef;
    justify-content: center;
    z-index: 999;
    column-gap: 6px;
    align-items: flex-start;
    margin-top: -16px; */
}

.bodyLeft {
    flex: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: pink;
    flex-direction: column;
    height: 1110px;
    border-radius: 10px;

}

.singlePostWrapper{
    padding: 30px 10px;
    /* padding-right: 0; */
}

.imgWrap{
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    border-left: 2px solid black;
    border-radius: 10px;
    /* margin-top: -130px; */


}


.singlePostImage{
    width: 400px;
    height:auto;
    border-radius: 5px;
    object-fit: cover;
    padding:0 20px 20px 20px;

}


.singlePostTitle{
    /* text-align: center; */
    display: flex;
    justify-content:center;
    margin: 10px 0;
    text-align: center;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color:blue;


}


.editContainer{
    float: right !important;
    font-size: 20px;
    margin: 10px 0;

}

.singlePostIcon{
    margin-left: 10px;
    cursor: pointer;

}

.singlePostIcon:first-child{
    color:teal;
}

.singlePostIcon:last-child{
    color:tomato;
}

.singlePostInfo{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    font-family: Josef;
    color:blue;
}

.des {
    text-align: justify;
    font-size: 16px;
    line-height: 30px;
    color:rgb(17, 14, 14);
    text-align-last: center;
}

.des::first-letter{
    margin-left: 100px;
    font-size: 30px;
    min-width: 600px;

}


/* sidebar css */

.sidebar {
    flex: 3;
    text-align: center;
    background: linear-gradient(blue, white, orange, pink, yellow);
    border-radius: 10px;
    z-index: -1;
    padding: 30px 0;
    height: 1110px;


}

.sidebarImage {
    display: inline-block;
    width: 400px;
    height: 300px;
    margin: 10px 30px;
    overflow: hidden;
    /* border: 2px solid red; */
}

.sidebarTitle {
    margin: 40px 30px;
    display: block;
    border-top: 2px solid rgba(139, 133, 133, 0.826);
    border-bottom: 2px solid rgba(139, 133, 133, 0.826);
    padding: 10px 0;
    font-size: 20px;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #dc3535;
}

.sidebarListItem {
    list-style: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidebarSocial {
    display: flex;
    justify-content: space-evenly;
}


.sidbarIcons {
    font-size: 25px;
    color: blue;
    display: block;


}



/* pagination */

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-container .pagination {
    position: relative;
    height: 60px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    border-radius: 2px;
}

.pagination-container .pagination li {
    list-style-type: none;
    display: inline-block;
}

.pagination-container .pagination li a {
    position: relative;
    padding: 20px 25px;
    text-decoration: none;
    color: blue;
    font-weight: 500;
}

.pagination-container .pagination li a:hover,
.pagination-container .pagination li.active a {
    background: rgba(255, 255, 255, 0.2);
}





@media screen and (max-width:1050px) {


    .singlePostImage{
        width: 100%;
        height:auto;

    }

}


@media screen and (max-width:784px) {



    .sidebarTitle{
        margin: 22px 10px;
        font-size: 36px;
    }


    l, ul {
        padding-left:0 !important;

      }

      .pagination-container .pagination li a{
        padding: 8px 10px;
        /* 6203261936 */
    }



}


@media screen and (max-width:550px){
    .singlePostTitle{
        font-size: 35px;
    }
}


@media screen and (max-width:450px) {


    .sidebarTitle{
        margin: 22px 10px;
        font-size: 26px;
    }

    .des::first-letter{
        margin-left: 55px;

    }

    .singlePostTitle{
        font-size: 26px;
    }


    .sidebarTitle {
        font-size: 20px;
      }

      p{
        padding: 10px !important;
      }

      .singlePostInfo {
        font-size: 12px;
      }

      .des {
        font-size: 13px;
        }
      .pagination-container .pagination li a{
        padding: 6px 8px;
        /* 6203261936 */
    }

    .editContainer {
        font-size: 16px;
        margin: 17px 0;
      }


}

