.single {
    width: 100%;
    text-align: center;
    display: table;
    border-spacing: 5px;
    font-family: Josef;
    z-index: 999;

}

.bodyLeft {
    display: table-cell;
    background-color: pink;
    border-radius: 10px;
    height: auto;
    width:90%;

}

.singlePostWrapper {
    margin-bottom: auto;
}

.imgWrap {
    border: 0 2px solid black 2px solid black 2px solid black;
    border-radius: 10px;
    width: 70%;
    margin: 0 auto;


}



.singlePostImage {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    padding: 0 20px 20px 20px;



}


.singlePostTitle {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    text-align: center;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: blue;


}


.editContainer {
    float: right !important;
    font-size: 20px;
    margin: 10px 0;

}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;

}

.singlePostIcon:first-child {
    color: teal;
}

.singlePostIcon:last-child {
    color: tomato;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    font-family: Josef;
    color: blue;
}

.des {
    text-align: justify;
    font-size: 16px;
    line-height: 30px;
    color: rgb(17, 14, 14);
    text-align-last: center;
}

.des::first-letter {
    margin-left: 100px;
    font-size: 30px;
    min-width: 600px;

}


/* sidebar css */

.sidebar {
    flex: 3;
    text-align: center;
    background: linear-gradient(blue, white, orange, pink, yellow);
    border-radius: 10px;
    z-index: -1;
    display: table-cell;
    vertical-align: top;

}

.sidebarImage {
    display:block;
    width: 100%;
    height:auto;
    margin: 0 auto;
    /* overflow: hidden; */
    padding: 0 15px;
}

.sidebarTitle {
    margin: 40px 30px;
    display: block;
    border-top: 2px solid rgba(139, 133, 133, 0.826);
    border-bottom: 2px solid rgba(139, 133, 133, 0.826);
    padding: 10px 0;
    font-size: 20px;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #dc3535;
}

.sidebarListItem {
    list-style: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidebarSocial {
    display: flex;
    justify-content: space-evenly;
}


.sidbarIcons {
    font-size: 25px;
    color: blue;
    display: block;


}



/* pagination */

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.pagination-container .pagination {
    position: relative;
    height: 60px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    border-radius: 2px;
}

.pagination-container .pagination li {
    list-style-type: none;
    display: inline-block;
}

.pagination-container .pagination li a {
    position: relative;
    padding: 20px 25px;
    text-decoration: none;
    color: blue;
    font-weight: 500;
}

.pagination-container .pagination li a:hover,
.pagination-container .pagination li.active a {
    background: rgba(255, 255, 255, 0.2);
}








@media screen and (max-width:784px) {


    .sidebarImage {
        width: 100%;
        height: auto;

    }


    .sidebarTitle {
        margin: 22px 10px;
        font-size: 36px;
    }


    l,
    ul {
        padding-left: 0 !important;

    }

    .pagination-container .pagination li a {
        padding: 18px 0px;
    }


.singlePostImage {
    padding: 0 5px 5px 5px;
}




}


@media screen and (max-width:550px) {
    .singlePostTitle {
        font-size: 35px;
    }

    .imgWrap {
        width: 88%;
      }


    .pagination-container .pagination li {
        padding: 4px;
        font-size: 17px;
      }

      .bodyLeft {
        width:70%;

    }
}


@media screen and (max-width:450px) {


    .sidebarTitle {
        margin: 22px 10px;
        font-size: 26px;
    }


    .des::first-letter {
        margin-left: 55px;

    }

    .singlePostTitle {
        font-size: 26px;
    }


    .sidebarTitle {
        font-size: 20px;
    }

    p {
        padding: 10px !important;
    }

    .singlePostInfo {
        font-size: 12px;
    }

    .des {
        font-size: 13px;
    }


    .editContainer {
        font-size: 16px;
        margin: 17px 0;
    }



.singlePostImage {
    padding: 0 10px 10px 10px;
}

}