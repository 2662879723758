.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #fff;
}
.headerTitleSm{
    position:absolute;
    top: 450px;
    /* z-index: -1; */

}
.headerTitleLg{
    position:absolute;
    top: 400px;
    font-size: 30px;
    color: #dc3535;
    cursor: pointer;
}

.headerImage{
    width: 100%;
    height:450px;
    object-fit:cover;
}


@media screen and (max-width:552px){
    .headerImage{
        width: 100%;
        height:240px;
        object-fit:cover;
    }

    .headerTitleSm {
        top: 280px;
      }

      .headerTitleLg {
        position: absolute;
        top: 248px;
}
}