.formStyle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin:0 10%;
    /* height: 80vh; */

}

.form-control{
    border-width: 3px;
    border-color: blue;
    color: rgb(98, 210, 43);
    font-size: 15px;
    font-weight: 700;
}

.form-control:focus{
    border-width: 3px;
    border-color: rgb(255, 0, 106);
}

h1{
    font-family: Josef;
    font-weight: 800;
    /* margin-top:10%; */


}

label{
    font-family: Josef;
    font-weight: 800;
    color: red;
    font-size: 20px;
    display: block;
    text-transform: uppercase;
}

input[type="submit"]{
    text-transform: uppercase;
    font-family: Josef;
    font-weight: 800;
    font-size: 20px;
    background-color: rgb(203, 227, 227);
}