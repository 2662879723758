.Settings {
    width: 100%;
    text-align: center;
    padding: 5px 0;
    display: flex;
    align-items: center;
    font-family: Josef;
    justify-content: center;
    z-index: 999;
    column-gap: 10px;
    align-items: flex-start;
}

.settingsWrapper {
    flex: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: pink; */
    flex-direction: column;

}

.settingsTitle{
    width: 100%;
    display: flex;
    justify-content:space-around;
    background-color: aquamarine;
    align-items: center;
}

.settingsUpdateTitle{
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color:teal;
    cursor: pointer;
}

.settingsDeleteTitle{
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: red;
    font-style: 12px;
    cursor: pointer;
}

.updateIcon, .deleteIcon{
    font-size: 20px;
}

.settingsForm{
    display: flex;
    flex-direction: column;
    width: 74%;
}

.settingsPP{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.settingsPP > img{
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.settingsPPIcon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color:blue;
    color: rgb(189, 63, 63);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.settingsForm > label{
    font-size: 25px;
    margin-top: 30px;
    text-align: left;
}

.settingsForm > input{
    color: rgb(12, 47, 47);
    font-size: 16px;
    padding-left: 10px;
    margin: 10px 0;
    height: 30px;
    border: none;
    border-bottom: 3px solid rgb(224, 26, 26);
    border-radius: 10px;
}

.settingsSubmit{
    width: 170px;
    align-self: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin-top: 60px;
    cursor: pointer;
    width: 100%;
    font-size: 20px;
    letter-spacing: 3px;
}