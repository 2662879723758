.Login1 {
    background-color: aqua;
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(rgba(145, 175, 240, 0.5),rgba(255, 255,255,0.5)),  url("./login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.loginTitle{
    font-size: 40px;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}

.loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm > label {
    margin: 10px 0;
    font-size: 22px;
}

.loginInput{
    padding: 10px;
    background-color:transparent;
    border-bottom:1px solid rgb(229, 15, 15) ;
    border-top:1px solid rgb(35, 62, 212) ;
    border-right:1px solid rgb(54, 205, 82) ;
    border-left:1px solid rgb(254, 32, 217) ;
    color: red;
    border-radius: 5px;
}

.loginButton{
    margin-top: 50px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    /* color: white; */
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.registerButton {
    position: absolute;
    top: 20px;
    right: 150px;
    font-size: 35px;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 5px;
    background-color: transparent;
    cursor: pointer;
}



@media screen and (max-width:500px) {

    .registerButton {
        right: 0;
}
}